import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@app/shared/interfaces/abstract/query.params';
import { IClientIssueActivity } from '@shared-global/interfaces/clients-issues-activities/interfaces/client-issue-activity.interface';
import { IFindClientsIssuesActivitiesByCriteriaRequest } from '@shared-global/interfaces/clients-issues-activities/interfaces/find-clients-issues-activities-by-criteria-request.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

export type ClientIssueActivitiesRequests = IFindClientsIssuesActivitiesByCriteriaRequest &
  QueryParamsInterface;

@Injectable({
  providedIn: 'root',
})
export class ClientIssueActivitiesService extends AbstractCrudService<
  IClientIssueActivity,
  ClientIssueActivitiesRequests
> {
  protected override feature = 'clients-issues-activities';
  protected override endPoint = `/clients-issues-activities/`;
}
